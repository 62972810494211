<template>
	<div>
    <h4>Sent Messages</h4>
		<div class="mb-3 mt-1">
			
		</div>


    <vue-good-table
      :columns="columns"
      :rows="messages"
      styleClass="vgt-table condensed"
      compactMode
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 5,
      }"
    >
     <template slot="table-row" slot-scope="props">
        

        <span v-if="props.column.field == 'created'">
          <span>{{formatDate(props.row.created)}}</span>
        </span>

        <span v-if="props.column.field == 'message'">
          <span>{{props.row.message}}</span>
        </span>

        <span v-if="props.column.field == 'name'">
          <span>{{props.row.name}}</span>
        </span>

      </template>

      
    </vue-good-table>


	</div>
</template>

<script>
import * as moment from 'moment'

export default {
  props: ['messages'],
  data: () => ({
    performingRequest: false,
    message: '',
    columns: [
      
      {
        label: 'Message',
        field: 'message',
      },
      {
        label: 'Sent',
        field: 'created',
        width:'100px',
      },
      {
        label: 'From',
        field: 'name',
        width:'120px',
      },
    ]
  }),
  methods: {
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.columns = null
    delete this.columns
    this.performingRequest = null
    delete this.performingRequest
    this.message = null
    delete this.message
  }
}
</script>